@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
* {
font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif
}

@font-face {
  font-family: 'Akzid';
  src: url('../src/fonts/AKZIDGROSTDBOLEXT.TTF') format('truetype')
  /* Add additional font formats if necessary */
  /* Specify the correct path to the font files */
}

@font-face {
  font-family: 'gotham';
  src: url('../src/fonts/GOTHAMBOLD.OTF') format('opentype')
  /* Add additional font formats if necessary */
  /* Specify the correct path to the font files */
}

@font-face {
  font-family: 'gothammed';
  src: url('../src/fonts/GOTHAMMEDIUM.TTF') format('truetype')
  /* Add additional font formats if necessary */
  /* Specify the correct path to the font files */
}

.menu {
  justify-content: center;
}
body{
  margin:0px;
  padding:0px;
  overflow-x: hidden;
  width: 100%;
  background-image: url("images/background-web.jpg");
  background-size: cover;
  font-family: sans-serif;
}

ul{
  list-style: none;
}
a{
  text-decoration: none;
}
button{
  outline: none;
  border: none;
}
input{
  outline: none;
  border: none;
}
.logo img{
  width: 90px;
}
#main{
  margin-top:0px;
  width:100%;
  display: flex;
  flex-direction: column;
  position: relative;
  background-repeat: no-repeat;
  object-fit: cover;
  background-size: cover;
  }
nav{
display: flex;
justify-content: space-around;
align-items: center;
box-shadow: 5px 10px 30px rgba(0, 0, 0, 0.336);
left: 0;
top:0;
width:100%;
z-index: 1;
background-color: #0d6897;
}
nav ul{
display: flex;
}
.active{
  background-color: #0d6897;
  color:#ffffff;
box-shadow: 5px 10px 30px rgba(0, 0, 0, 0.336);
transition: all ease 0.2s;
}
nav ul li a{
font-family: 'Akzid';
height:40px;
line-height: 43px;
justify-content: center;
margin: 3px;
padding: 0px 22px;
display: flex;
font-size: 1.5rem;
text-transform: uppercase;
font-weight: 500;
color:#ffffff;
  letter-spacing: 1px;
  border-radius: 3px;
  transition: 0.2s ease-in-out;
}
.hey{
  font-family: calibri;
color:#ffffff;
font-weight: 500;
font-size: 0.9rem;
border-bottom: 2px solid #000266;
}
.img-mobile{
display: none;
}
nav ul li a:hover{
  background-color: #0d6897;
  color:#ffffff;
  cursor:grab;
  box-shadow: 5px 10px 30px rgba(0, 0, 0, 0.336);
transition: all ease 0.2s;
}
nav .menu-btn,
.menu-icon{
  display:none;
}
.name{
font-family: sans-serif;
width: 500px;
position: absolute;
  left: 50%;
  text-align: center;
top: 50%;
transform: translate(-50%,-50%);
}
.name span{
  color: #d0ff00;
  border-bottom: 2px solid #d0ff00;
}
.name .details{
  color: #969696;
}
.name h1{
font-family:sans-serif;
font-size: 46px;
margin:0px;
letter-spacing: 3px;
color:#ffffff;
}
.header-btns{
  display: flex;
  margin-top: 40px;
  margin-left: 6.8em;
}


.arrow{
  align-self: end;
  width: 50%;
  height: 15%;
  margin-bottom: 4em;
  position: absolute;
  bottom:0px;
  border-right: 2px solid  #d0ff00;
  animation: arrow-animation ease 1.5s;

}
.arrow::after{
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 11px 11px 0px 11px;
  border-color: #d0ff00 transparent transparent transparent;
  right: -0.7em;
  bottom: -2px;
}
@keyframes arrow-animation{
  0%{
      bottom: 70px;
      opacity: 0.2;
  }
  100%{
      bottom: 5px;
      opacity: 1;
  }
}
.f-heading h1{
  color:#FFFFFF;
  font-size: 2.5rem;
  margin: 0px;
  padding: 0px;

}
.f-heading p{
  color:rgba(247, 247, 247, 0.678);
  font-size: 1rem;
  margin-top: 10px;
  padding: 0px;
}
.f-heading{
  text-align: center;
  margin-top: 60px;
  margin-bottom: -120px;
}
#hadiah{
margin-top:-50px ;
width:100%;
height: 40vh;
box-sizing: border-box;
flex-direction: row;
display: flex;
align-items: center;
justify-content: space-between;
padding: 0px 5% 0px 5%;
position: relative;
}
#daftar{
  margin-top:0px ;
  width:100%;
  height: auto;
  box-sizing: border-box;
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 5% 0px 5%;
  position: relative;
  }

.hadiah-text{
  width: 45%;
  color: #fff;
  list-style: circle;
  display: flex;
  flex-direction: column;
}
.syarat-text{
  width: 100%;
  color: #fff;
  
  align-items: center;
  display: flex;
  flex-direction: column;
  
}
.hadiah-text h1{
  font-size: 2.3rem;
  font-family: 'gotham';
  font-weight: bold;
  color:#fff;
  }
.hadiah-text h2{
font-family: sans-serif;
font-size: 1.3rem;
color:#fff;
}
.hadiah-text h2{
margin: 0px;
padding: 0px;
}
.hadiah-text p{
font-size: 1rem;
color:#b4b4b4;
white-space: pre-line;
}

.syarat-text p{
  font-size: 1.2rem;
  align-items: center;
  text-align: justify;
  background-color:#536372;
  padding: 30px 30px 30px 30px;
  display: flex;
  width: 80%;
  color:#fff;
  white-space: pre-line;
  }

.hadiah-text button{
  margin-top: 20px;
width: 140px;
height: 40px;
border-radius: 10px;
border: none;
  outline: none;
  color: #000266;
background-color:#d0ff00;
}
.hadiah-text button:hover{
background-color: #ffffff;
  transition: all ease 0.3s;
  color: #000266;
}
.hadiah-model{
  width: 50%;
}
.hadiah-model img{
width: 100%;
height: auto;
align-items: stretch;
}

#services{
  margin-top: 0px;
  width:100%;
  height:70vh;
  box-sizing: border-box;
  font-family: calibri;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 0px ;
}

#caragabung{
  margin-top: 100px;
  width:100%;
  height:auto;
  box-sizing: border-box;
  font-family: calibri;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 0px ;
}
#syarat{
  width:100%;  
  height: auto;
  box-sizing: border-box;
  font-family: calibri;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 0px ;
}
.s-heading h1{
  color:#f8dbaa;
  font-size: 8rem;
  letter-spacing: 0px;
  font-family: 'Akzid';
  font-weight: bold;
  margin-top: 0px;
  padding: 0px;
}

.s-heading{
  text-align: center;
}

.s-b-img{
  width: 100%;
  height: 50%;

}
.s-b-img img{
  width:100%;
  height: 100%;

}
.s-b-text{
  width: 100%;
  height: 40%;
  background-color:#ffffff;
  display:flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
}
.s-b-text p{
  margin: 0px;
  color:#000266a6;
  font-size: 1.1rem;
  font-family: calibri;
  display: block;
  display: -webkit-box;
  max-width: 80%;
  margin-top: 25px;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.s-b-text p:hover{
  color:#000266;
}
.s-b-text a{
  margin-top:15px ;
}
.s-type{
  font-family: calibri;
  color:#000266;
  background-color: #ffffff;
  padding: 10px 15px;
  position: absolute;
  top: 0px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 18px;
  border-radius: 0px 0px 10px 10px;
  box-shadow: 2px 2px 12px rgba(0,0,0,0.2);
}
.s-box:hover{
  transform:translateY(-10px);
  transition: all ease 0.3s;

}

/*******RESPONSIVE*******/
@media(max-width:1190px){
#main{
  background-size: 1150px;
}

.name{
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
}
.header-btns{
  margin-left: 10em;
}

.hadiah-model img{
  height:400px;
  align-items: stretch;
  }
  #services{
  height:auto;
  }
  .b-container{
  flex-wrap: wrap;
}
  .s-box{
  flex-grow: 1;
}
.s-b-img img{
  object-fit:cover;
}

}
@media(max-width:1100px){.menu-icon{
  display:block;}
      nav{
          justify-content: space-between;
          height: 65px;
          padding: 0px 0px;
      }
      .logo img{
          width:70px;
      }
      .header-btns{
          margin: 0;
      }
      .menu{
          display:flex;
          font-size:0.2rem;
          top:65px;
          left:0px;
          width:100%;
          padding:0px;
          margin:0px;
      }
      .nav{
        align-items: center;
      }
      nav ul li a{
        font-family: 'Akzid';
      height:40px;
      line-height: 23px;
      margin: 3px;
      padding: 0px 22px;
      justify-content: center;
      display: flex;
      font-size: 1rem;
      text-transform: uppercase;
      color:#ffffff;
        letter-spacing: 1px;
        border-radius: 3px;
        transition: 0.2s ease-in-out;
      }
     }

@media(max-width:1000px){
  #main{
    background-size: 970px;
  }
  .hadiah-text h2{
    font-family: sans-serif;
    font-size: 1.3rem;
    color:#fff;
   }
  #daftar{
    margin-top: 80px;
    height: auto;
    justify-content: center;
    flex-direction: column;
    padding-top: 0px;
    font-size: 2rem;
    text-align: center;
    }

    #caragabung{
      margin-top: 50px;
      justify-content: center;
      flex-direction: column;
      padding-top: 0px;
      font-size: 2rem;
      text-align: center;
      }
  #services{
    margin-top: 0px;
  }
  #syarat {
    height: auto;
    }
.hadiah-model{
  display:flex;
  width: 80%;
  align-items: stretch;
  margin-top: 15px;
}
.hadiah-model img{
  height: auto;
  align-items: stretch;
}
.hadiah-text h1 {
  
  font-family: 'gotham';
  font-size: 2rem;
}
.s-heading h1{
  margin-top: 00px;
  font-size: 4rem;
  font-family: 'Akzid';
  font-weight: bold;
  color: #f8dbaa;
}

.hadiah-text h2{
  
font-family: sans-serif;
}
.b-container{
  margin-top: 30px;
}
#subscribe h3{
  font-size: 2.5rem;
}
#hadiah{
  margin-top: -30px;
  justify-content: center;
  flex-direction: column;
  padding-top: 0px;
  font-size: 2rem;
  text-align: center;
}
.f-heading{
  display: none;
}
.hadiah-text{
  width: 90%;
  align-items: center;
}
.hadiah-model img {
  height: 300px;
  align-items: stretch;
}

}


@media screen and (max-width:780px){
  #caragabung{
    height: auto;
  }
  .s-heading h1{
    margin-top: 00px;
    font-size: 3rem;
    font-family: 'Akzid';
    font-weight: bold;
    color: #f8dbaa;
  }
  .hadiah-text h1 {
    
  font-family: 'gotham';
    font-size: 1.5rem;
  }
  .hadiah-model img {
    height: 250px;
    align-items: stretch;
  }
  .hadiah-text h2{
    font-size: 1.3rem;
  }
  #syarat{
    height: auto;
  }
  #daftar{
    margin-top: 50px;
    height: auto;
  }
  #hadiah{
  margin-top: 50px;
  }
  }
 
@media(max-width:600px){
  #main{
    background-size: 600px;
  }
  nav ul li a{
    font-family: 'Akzid';
  height:40px;
  line-height: 23px;
  margin: 3px;
  padding: 0px 22px;
  justify-content: center;
  display: flex;
  font-size: 0.9rem;
  text-transform: uppercase;
  color:#ffffff;
    letter-spacing: 1px;
    border-radius: 3px;
    transition: 0.2s ease-in-out;
  }
.name{
  width:60%;
  }}
  .scroll{
      left: 42.5%;
  }

      @media(max-width:470px){
        .img-desktop{
          display: none;
          }
        .img-mobile{
          display: flex;
        }  
        #hadiah {
          margin-top: 0px;
          justify-content: center;
          flex-direction: column;
          height: 50vh;
          padding-top: 0px;
          font-size: 2rem;
          text-align: center;
        }
        #services {
          margin-top: 20px;
        }
     
        #caragabung{
          margin-top: 0px;
         }

        nav ul li a{
        height:40px;
        font-family: 'Akzid';
        justify-content: center;
        width: 70px;
        line-height: 23px;
        margin: 3px;
        padding: 5px 5px;
        display: inline;
        font-size: 0.4rem;
        text-transform: uppercase;
        font-weight: bold;
        color:#ffffff;
          letter-spacing: 1px;
          border-radius: 3px;
          transition: 0.2s ease-in-out;
        }
        .s-heading{
          height: auto;
          margin-top: 20px;
        }
          .s-heading h1{
            margin-top: 0px;
            font-size: 2.5rem;
            font-weight: 800;
            font-family: 'Akzid';
            font-weight: bold;
            color: #f8dbaa;
          }
         .hadiah-text h2{
          
          font-family: sans-serif;
          font-size: 1.3rem;
          color:#fff;
        }
         .hadiah-text h1{
          
          font-family: 'gotham';
          margin-top: 0px;
          font-size: 1.5rem;
          font-weight: bold;
          color:#fff;
          }
         .hadiah-model{
          width: 100%;
          height: auto;
          align-items: stretch;
         }
         .hadiah-model img{
          height: 200px;
         }
         .syarat-text p{
          font-size: 0.8rem;
          align-items: center;
          text-align: justify;
          background-color:#536372;
          padding: 20px 30px 20px 5px;
          display: flex;
          width: 80%;
          color:#fff;
          white-space: pre-line;
          }

          #daftar{
            margin-top: 50px;
            height: auto;
            justify-content: center;
            flex-direction: column;
            padding-top: 0px;
            font-size: 2rem;
            text-align: center;
            }
            #syarat {
              margin-top: 0px;
              width: 100%;
              height: auto;
              font-family: calibri;
              display: flex;
              flex-direction: column;
              align-items:center;
              justify-content: start;
              padding-top: 0px;
          }
          a {
            margin-top: -20px;
            margin-bottom: 10px;
            height: 100px;
        }
      }
      @media(max-width:391px){
        nav ul li a{
          height:20px;
          font-family:'Akzid';
          justify-content: center;
          width: 70px;
          line-height: 15px;
          margin: 3px;
          display: inline;
          font-size: 0.35rem;
          text-transform: uppercase;
          font-weight: bold;
          color:#ffffff;
            letter-spacing: 1px;
            border-radius: 3px;
            transition: 0.2s ease-in-out;
          }

        #services {
          margin-top: 10px;
      }
      .s-heading{
        margin-bottom: 0px;
      }
      .s-heading h1{
        font-family: 'Akzid';
        font-weight: bold;
        font-size: 1.5rem;
      }
      #caragabung {
        margin-top: 100px;
       }
       #daftar {
        margin-top: 80px;
       }
       #syarat {
        margin-top: 20px;
       }
        }
        @media screen and (max-height:950px) and (min-width:800px) {
          .hadiah-text h2{
            
            font-family: sans-serif;
            font-size: 1.3rem;
            }
            .hadiah-model img{
              height: 300px;
            }

            #daftar {
              height: auto;
            }

            #caragabung {
              margin-top: 0px;
            }

            #syarat {    
              margin-top: 0px;          
              height: auto;
            }
            .syarat-text p{
              font-size: 0.8rem;
              align-items: center;
              text-align: justify;
              background-color:#536372;
              padding: 20px 30px 20px 5px;
              display: flex;
              width: 80%;
              color:#fff;
              white-space: pre-line;
              }

            .s-heading h1{
              font-family: 'Akzid';
              font-weight: bold;
              font-size: 5rem;
            }
        }

        @media screen and (max-height:750px) and (min-width:800px) {
          .hadiah-text h2{  
            font-family: sans-serif;
            font-size: 1.3rem;
            }
            .hadiah-model img{
              height: 300px;
            }
            #daftar {
              height: auto;
            }
            #caragabung {
              margin-top: 0px;
            }
            #syarat {    
              margin-top: 30px;          
              height: auto;
            }
            .syarat-text p{
              font-size: 0.8rem;
              align-items: center;
              text-align: justify;
              background-color:#536372;
              padding: 20px 30px 20px 5px;
              display: flex;
              width: 80%;
              color:#fff;
              white-space: pre-line;
              }

            .s-heading h1{
              font-family: 'Akzid';
              font-weight: bold;
              font-size: 4rem;
            }
        }

        @media screen and (max-height:680px) and (max-width:450px) {
          .hadiah-text h2{
            font-family: sans-serif;
            font-size: 1rem;
            }
            .hadiah-model img{
              height: 150px;
            }

            #daftar {
              height: 50vh;
            }

            #caragabung {
              margin-top: 0px;
              height: 120vh;
            }

            #syarat {    
              margin-top: 0px;          
              height: auto;
            }
            .syarat-text p{
              font-size: 0.8rem;
              align-items: center;
              text-align: justify;
              background-color:#536372;
              padding: 20px 30px 20px 5px;
              display: flex;
              width: 80%;
              color:#fff;
              white-space: pre-line;
              }
             
            .s-heading h1{
              font-family: 'Akzid';
              font-weight: bold;
              font-size: 1.5rem;
            }

            nav ul li a{
              height:40px;
              font-family: 'Akzid';
              justify-content: center;
              width: 70px;
              line-height: 23px;
              margin: 3px;
              display: inline;
              font-size:auto;
              text-transform: uppercase;
              font-weight: bold;
              color:#ffffff;
                letter-spacing: 1px;
                border-radius: 3px;
                transition: 0.2s ease-in-out;
              }
              .hadiah-model {
                width: 100%;
                height: 150px;
                align-items: stretch;
            }
            #hadiah {
              margin-top: 20px;
              height: 35vh;
              padding-top: 0px;
              font-size: 2rem;
              text-align: center;
          }
        }

        @media(max-width:300px){
          nav ul li a{
            height:20px;
            font-family: 'Akzid';
            justify-content: center;
            width: 70px;
            line-height: 15px;
            margin: 3px;
            display: inline; 
            font-size:auto;
            text-transform: uppercase;
            font-weight: bold;
            color:#ffffff;
              letter-spacing: 1px;
              border-radius: 3px;
              transition: 0.2s ease-in-out;
            }
          }

          @media screen and (max-height:550px) and (max-width:350px) {
            .hadiah-text h2{
              
              font-family: sans-serif;
              font-size: 0.8rem;
              }
              .hadiah-model img{
                height: 100px;
              }
  
              #daftar {
                height: 50vh;
              }
  
              #caragabung {
                margin-top: 0px;
                height: 120vh;
              }
  
              #syarat {    
                margin-top: 0px;          
                height: auto;
              }
              .syarat-text p{
                font-size: 0.8rem;
                align-items: center;
                text-align: justify;
                background-color:#536372;
                padding: 20px 30px 20px 5px;
                display: flex;
                width: 80%;
                color:#fff;
                white-space: pre-line;
                }
               
              .s-heading h1{
                font-family: 'Akzid';
                font-weight: bold;
                font-size: 1.5rem;
              }
  
              nav ul li a{
                height:40px;
                font-family: 'Akzid';
                justify-content: center;
                width: 70px;
                line-height: 23px;
                margin: 3px;
              
                display: inline; 
                font-size:auto;
                text-transform: uppercase;
                font-weight: bold;
                color:#ffffff;
                  letter-spacing: 1px;
                  border-radius: 3px;
                  transition: 0.2s ease-in-out;
                }
                .hadiah-model {
                  width: 100%;
                  height: 150px;
                  align-items: stretch;
              }
              #hadiah {
                margin-top: 20px;
                height: 35vh;
                padding-top: 0px;
                font-size: 2rem;
                text-align: center;
            }
          }